<template>
  <div class="cvv-confirmation">
    <div class="headline-small">
      {{ $t("wallet.cvv.confirmation") }}
    </div>

    <div class="mt-s24 body-text-large">
      {{ $t("wallet.cvv.description") }}
    </div>

    <div class="mt-s24">
      <CustomInput v-model="cvv" type="tel" testId="cvv" mask="####">
        <template>
          <div class="body-text-x-large">{{ $t("wallet.cvv.enter") }}</div>
        </template>
      </CustomInput>
    </div>

    <div class="mt-s12">
      <ButtonV2
        @onClick="submitCvv"
        :label="$tc('wallet.cvv.submit')"
        testId="btn-submit"
        :inactive="!cvv || isLoading"
        wide
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ButtonV2, CustomInput } from '@/components/misc';
import MethodEventName from '@/enums/MethodEventName';

export default {
  name: 'CardCcvConfirmation',
  components: {
    ButtonV2,
    CustomInput,
  },

  data() {
    return {
      cvv: null,
      isLoading: false,
    };
  },

  mounted() {
    this.$store.dispatch('events/track', {
      event: 'CREDIT_CARD_CVV_VIEWED',
    });
  },

  computed: {
    ...mapState('wallet', ['ongoingDepositData']),
  },

  methods: {
    ...mapActions('wallet', ['handleCard3ds']),
    ...mapActions('ui', ['setIsOverlayLoading']),

    formatCurrency(number) {
      return this.numberFormat(number, 2, false, true);
    },

    async submitCvv() {
      try {
        this.isLoading = true;
        this.setIsOverlayLoading(true);
        this.$store.dispatch('events/track', {
          event: 'DEPOSIT_SUBMITTED',
          variables: {
            amount: this.formatCurrency(
              this.lodashGet(this.ongoingDepositData, 'amount', 0)
            ),
            fee: this.formatCurrency(
              this.lodashGet(this.ongoingDepositData, 'fee', 0)
            ),
            payment_type: MethodEventName.card,
          },
        });

        await this.handleCard3ds(this.cvv);
      } finally {
        this.isLoading = false;
        this.setIsOverlayLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.cvv-confirmation {
  @apply w-full;
  max-width: 370px;
}
</style>
